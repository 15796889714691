import IMask from 'imask';

let initializedMasks = [];

export const initMasks = () => {
  const phones = document.querySelectorAll('input[data-phone=true]');
  const maskedInputs = [];

  if (phones.length > 0) {
    phones.forEach(el => maskedInputs.push({
      inputsFields: el,
      options: { mask: '+{7}(000)000-00-00', lazy: false } }));
  }

  // init masks
  if (maskedInputs.length > 0) {
    if (initializedMasks.length > 0) {
      initializedMasks.map(mask => mask.destroy());
      initializedMasks = [];
    }

    maskedInputs.forEach(item => {
      const { inputsFields, options } = item;
      const mask = IMask(inputsFields, options);
      initializedMasks.push(mask);
    });
  }
};

document.addEventListener('DOMContentLoaded', () => {
  initMasks();
});
