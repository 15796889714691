<template>
  <div class="calendar-app">
    <div class="booking__header">
      <div class="booking__calendar">
        <div class="btn primary">
          <a v-on:click.prevent="loadPreviousDay" class="prev">
            <svg-icon icon="ap"/>
          </a>
          <span class="text" @click='showDatePicker'>
            {{ currentDateFormat }}
          </span>
          <a v-on:click.prevent="loadNextDay" class="next">
            <svg-icon icon="an"/>
          </a>
        </div>
      </div>
      <div class="contacts">
        <a href="tel:+79850681139" class="phone">
          <small>бронированиe на день</small>
          <span>+7 (985) 068 11 39</span>
        </a>
        <ul class="phone__apps">
          <li>
            <a href="tel:+79850681139">
              <svg-icon icon="ph"/>
            </a>
          </li>
          <li>
            <a href="https://wa.me/+79850681139">
              <svg-icon icon="wa"/>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="booking__table">
      <table>
        <thead>
        <tr>
          <th>&nbsp;</th>
          <td v-for="room in rooms" align="center">{{ room.name }}</td>
        </tr>
        </thead>
        <tbody>
        <tr v-for="hour in hours">
          <th>{{ formatHour(hour) }}</th>
          <td v-for="room in rooms">
            <time-slot :status="roomStatus(room, hour)" :click="() => showForm(room, hour)"/>
          </td>
        </tr>
        </tbody>
      </table>
    </div>

    <div id="calendarPopup" class="modal mfp-hide sm">
      <div class="modal__header"><span>Выберите дату</span></div>

      <div class="modal__body">
        <div class="row" style="margin-bottom: 0.5em;">
          <date-picker
              :inline="true"
              v-model="calendarDate"
              :lang="lang"></date-picker>
        </div>
      </div>
      <div class="modal__footer">
        <button class="btn dark" v-on:click="setCalendarDate">Ок</button>
      </div>
    </div>

    <div id="reservationForm" class="mfp-hide">
      <div class="banners-block sm" v-for="banner in banners">
        <div class="banner" v-html="banner.content"></div>
      </div>
      <div class="modal2 modal sm">
        <div class="modal2__header"><span>Забронировать</span></div>
        <div class="modal2__body">
          <div style="margin-bottom: 0.5em">
            Выбранная дата: {{ currentDateFormat }}
            <br>
            Выбранный кабинет: {{ currentRoom.name }}
          </div>
    
          <div class="form__input">
            <label>Время начала бронирования</label>
            <div class="select--input">
              <select v-model='form.timeStart'>
                <option v-for="value in reservationTimeOptions" v-bind:value="value" :selected="form.timeStart === value">
                  {{ value }}
                </option>
              </select>
            </div>
            <p class="error" v-if="errors.date_start">{{ errors.date_start.join(',')}}</p>
          </div>
    
          <div class="form__input">
            <label>Время окончания бронирования</label>
            <div class="select--input">
              <select v-model='form.timeEnd'>
                <option v-for="value in reservationTimeOptions" v-bind:value="value" :selected="form.timeEnd === value">
                  {{ value }}
                </option>
              </select>
            </div>
            <p class="error" v-if="errors.date_end">{{ errors.date_end.join(',')}}</p>
          </div>
    
          <div class="">
            Общая стоимость: {{ roomPrice }}₽
          </div>
        </div>
        <div class="modal2__footer">
          <p v-if="form.loading">
            Загрузка...
          </p>
          <button class="btn dark" v-on:click="createReservation" :disabled="form.submitDisabled">Забронировать</button>
        </div>
        <button title="Close (Esc)" type="button" class="mfp-close">×</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios"
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/locale/ru';

import SvgIcon from './svg_icon.vue';
import TimeSlot from './time_slot.vue';

export default {
  components: {
    DatePicker,
    SvgIcon,
    TimeSlot,
  },
  data: function () {
    return {
      rooms: [],
      banners: [],
      reservations: {},
      currentDate: new Date(),
      authorized: false,
      hours: [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
      token: $('meta[name=csrf-token]').attr('content'),

      currentRoom: {name: ''},
      reservationTimeOptions: [],
      calendarDate: null,
      form: {
        timeStart: null,
        timeEnd: null,
        submitDisabled: false,
        loading: false,
      },
      errors: {
        date_start: [],
        date_end: [],
      },
      lang: {
        formatLocale: {
          firstDayOfWeek: 1,
        },
        monthBeforeYear: true,
      },
    }
  },
  created() {
    this.parseData();
  },
  methods: {
    showForm(room, hour) {
      if (!this.authorized) {
        $.magnificPopup.open({
          fixedContentPos: true,
          fixedBgPos: true,
          items: {
            src: '#loginform',
            type: 'inline',
          }
        });
        return
      }
      this.errors = {
        date_start: [],
        date_end: [],
      };
      this.currentRoom = room;

      let nextHour = hour + 1;
      if (nextHour === 24) nextHour = 0;

      this.form.timeStart = this.formatHour(hour);
      this.form.timeEnd = this.formatHour(nextHour);

      $.magnificPopup.open({
        fixedContentPos: true,
        fixedBgPos: true,
        items: {
          src: '#reservationForm',
          type: 'inline',
        }
      });
    },
    showDatePicker() {
      $.magnificPopup.open({
        fixedContentPos: true,
        fixedBgPos: true,
        items: {
          src: '#calendarPopup',
          type: 'inline',
        }
      });
    },
    setCalendarDate() {
      $('#calendarPopup').magnificPopup('close');

      if (!this.calendarDate) return;

      this.currentDate = this.calendarDate;
      this.loadReservations();
    },
    createReservation() {
      this.errors = {
        date_start: [],
        date_end: [],
      };

      const self = this;
      self.form.loading = true;
      self.form.submitDisabled = true;

      let endDate = this.currentDate;

      if (this.form.timeEnd === '00:00') {
        const nextDate = new Date(this.currentDate);
        nextDate.setTime(nextDate.getTime() + 24 * 3600 * 1000);
        endDate = nextDate;
      }

      const reservation = {
        date_start: `${this.currentDate.format('yyyy-mm-dd')} ${this.form.timeStart} +0300`,
        date_end: `${endDate.format('yyyy-mm-dd')} ${this.form.timeEnd} +0300`,
        room_id: this.currentRoom.id,
      }

      axios.post(
          '/reservation/create', {reservation},
          {headers: {'X-CSRF-Token': this.token}}
      ).then(function (response) {
          console.log(response);

          if (response.status === 200 && response.data) {
            if (response.data.form_url) {
              window.location.href = response.data.form_url;
              return;
            }

            self.form.loading = false;
            self.form.submitDisabled = false;

            if (response.data.error) {
              alert(response.data.error);
              return;
            }
            alert('Ошибка оплаты, обратитесь к администратору');
          }
        }
      ).catch(function (error) {
        self.form.loading = false;
        self.form.submitDisabled = false;

        if (error.response.status === 422) {
          console.log('errors 422', error.response.data.errors);
          self.errors = error.response.data.errors;
        } else {
          console.log('some error', error);
          alert('Ошибка бронирования, проверьте свободны ли даты');
        }
      });
    },
    loadPreviousDay() {
      const prevDate = new Date(this.currentDate);
      prevDate.setTime(prevDate.getTime() - 24 * 3600 * 1000);
      this.currentDate = prevDate;
      this.loadReservations();
    },
    loadNextDay() {
      const nextDate = new Date(this.currentDate);
      nextDate.setTime(nextDate.getTime() + 24 * 3600 * 1000);
      this.currentDate = nextDate;
      this.loadReservations();
    },
    formatHour(hour) {
      if (hour < 10) {
        return `0${hour}:00`;
      } else {
        return `${hour}:00`;
      }
    },
    roomStatus(room, hour) {
      // reservations should be sorted by date_start
      const reservations = this.reservations[`r${room.id}`];
      const startTime = new Date(this.currentDate);
      startTime.setHours(hour);
      startTime.setMinutes(0);
      startTime.setSeconds(0);
      startTime.setMilliseconds(0);

      if (!reservations) {
        if (startTime < new Date()) return 'past';
        return 'free';
      }

      const endTime = new Date(this.currentDate);
      endTime.setHours(hour);
      endTime.setMinutes(59);
      endTime.setSeconds(59);
      endTime.setMilliseconds(0);

      const halfTime = new Date(this.currentDate);
      halfTime.setHours(hour);
      halfTime.setMinutes(30);
      halfTime.setSeconds(0);
      halfTime.setMilliseconds(0);

      // console.log('start/half/end', startTime, halfTime, endTime);
      let status = 'free';

      for (let i = 0; i < reservations.length; i++) {
        const entry = reservations[i];
        // console.log('check reservation', entry);

        if (entry.date_start <= startTime && endTime <= entry.date_end // ||
            // if starts in first 30 minutes, slot is busy
            //entry.date_start >= startTime && entry.date_start < halfTime
        ) {
          return 'busy';
        } else if (entry.date_start >= startTime && entry.date_start < halfTime) {
          // if ends in first half of slot
          status = 'half-busy';
        } else if (entry.date_end > startTime && entry.date_end <= halfTime) {
          // if ends in first half of slot
          status = 'half-busy';
        } else if (entry.date_start >= halfTime && entry.date_start <= endTime) {
          // if starts in second half
          if (status === 'free') {
            status = 'half-free';
          } else {
            status = 'busy';
          }
        }
      }

      if (status === 'free' && startTime < new Date()) return 'past';

      return status;
    },
    parseData() {
      const container = document.getElementById('calendar-container');
      this.rooms = JSON.parse(container.dataset.rooms);
      this.banners = JSON.parse(container.dataset.banners);
      this.authorized = container.dataset.authorized === 'true';
      const reservations = JSON.parse(container.dataset.reservations);
      this.setReservations(reservations);
      let result = [];

      for (let i = 0; i < this.hours.length; i++) {
        const hour = this.hours[i];
        result.push(this.formatHour(hour));
        result.push(`${hour < 10 ? '0' + hour : hour}:30`);
      }
      result.push('00:00');
      this.reservationTimeOptions = result;
    },
    setReservations(reservations) {
      let result = {};

      for (let i = 0; i < reservations.length; i++) {
        const item = reservations[i];
        item.date_start = new Date(item.date_start);
        item.date_end = new Date(item.date_end);
        const key = `r${item.room_id}`;

        if (result[key]) {
          result[key].push(item);
        } else {
          result[key] = [item]
        }
      }

      this.reservations = result;
    },
    loadReservations() {
      const self = this;
      this.reservations = {};
      const date = this.currentDate.format('yyyy-mm-dd');

      axios.get(
          `/reservations?date=${date}`
      ).then(function (response) {
        if (response.status === 200 && response.data) {
          self.setReservations(response.data.data);
        } else {
          console.log('unexpected status/response', response.status, response);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    }
  },
  computed: {
    currentDateFormat() {
      const days = ["Воскресенье", "Понедельник", "Вторник", "Среда", "Четверг", "Пятница", "Суббота"];

      const idx = this.currentDate.getDay();
      return `${days[idx]} ${this.currentDate.format('dd.mm.yyyy')}`;
    },
    roomPrice() {
      if (!this.form.timeStart) return 0;
      let today = this.currentDate.format('yyyy-mm-dd');
      const startTime = new Date(`${today} ${this.form.timeStart}`).getTime();

      if (this.form.timeEnd === '00:00') {
        const nextDate = new Date(this.currentDate);
        nextDate.setTime(nextDate.getTime() + 24 * 3600 * 1000);
        today = nextDate.format('yyyy-mm-dd');
      }

      const endTime = new Date(`${today} ${this.form.timeEnd}`).getTime();
      const delta = (endTime - startTime) / 1000 / 3600.0; // hours

      if (delta < 0) return 0;

      return delta * this.currentRoom.price;
    }
  }
}
</script>
