$(function(){
  var windows = $(window);
  var sticky = $('.header-sticky');
  
  $('.arrow-top').hide();
  
  windows.on('scroll', function() {
    var scroll = windows.scrollTop();
    if (scroll < 300) {
      sticky.removeClass('is-sticky');
      $('.arrow-top').hide();
    }else{
      sticky.addClass('is-sticky');
      $('.arrow-top').show();
    }
  });
  
  $('.arrow-top').click(function() {
    
    window.scrollTo(0, 0);
  });
});
