require("@rails/ujs").start();
global.toastr = require("toastr")

window.jQuery = $;
window.$ = $;

// require("turbolinks").start()
require("@rails/activestorage").start()
require("channels");
require("trix")
require("@rails/actiontext")
require("bootstrap/js/dist/tooltip")
require("./lib/carousel");
require("./lib/popup");
require("./lib/dateformat");
require("./lib/category_select");
require("./calendar");
require("./dropdown");
// require("./validator");
require("./validate/mask");
require("./lib/styki_menu");
import "select2";

const InitForms = function () {
	$("[data-plugin='select2']").each(function () {
		const $eventSelect = $(this);
		const $dropdownParent = $eventSelect.parents(".form-select:first");
		
		$eventSelect.select2({
			placeholder: "Select an option",
			width: "100%",
			dropdownParent: $dropdownParent,
		});
		
		$eventSelect.on("select2:open", function (e) {
			setTimeout(() => {
				/*createScrollbar($dropdownParent.find(".select2-results__options")[0]);*/
			}, 20);
		});
	});
	
	$(".form-datepicker").each(function () {
		const $input = $(this).find(".form-control");
		$input.datepicker({
			language: "en",
			dateFormat: "dd/mm/yyyy",
			autoClose: true,
		});
		//.data("datepicker")
		//.selectDate(new Date());
	});
};

const spriteLink = document.head.querySelector('link[rel="sprite"]');

var navText = [
	'<svg class="icon"><use xlink:href="' + spriteLink.href + '?#ap"></use></svg>',
	'<svg class="icon"><use xlink:href="' + spriteLink.href + '?#an"></use></svg>',
];

$(function(){
	InitForms();
	$("a.disabled").click(function (e) {
		e.preventDefault();
		return false;
	});
	
	$('.message__close').click(function (e) {
		$(this).closest('.message').fadeOut(250);
	});

	$('.btn-response').click(function(e){
		$('#newRequest').attr('action', '/announcements/' + $(this).data('id') + '/send_response');
	})
	
	$(".header .toggle").click(function () {
		if ($(this).hasClass("active")) {
			$(this).removeClass("active");
			$(".header__content").removeClass("show");
		} else {
			$(this).addClass("active");
			$(".header__content").addClass("show");
		}
	});

	$(".header .toggle").blur(function () {
		if ($(this).hasClass("active")) {
			setTimeout(() => {
				$(this).removeClass("active");
				$(".header__content").removeClass("show");
			}, 250);
		}
	});

	$('[data-show="popup"]').magnificPopup({
		type: "inline" ,
		fixedContentPos: true,
		fixedBgPos: true
	});

	$("[data-large]").magnificPopup({
		type: "image",
		fixedContentPos: true,
		fixedBgPos: true,
		image: {
			titleSrc: function (item) {
				return (
					item.el.data("name") + "<small>" + item.el.data("small") + "</small>"
				);
			},
		},
		zoom: { enabled: true },
		callbacks: {
			elementParse: function (item) {
				item.src = item.el.data("large");
			},
		},
	});

	$('.cabinet__img').each(function() {
		const el = $(this);
		const id = `#${el.attr('id')}`;
		let items = el.data('images').map(el => ({src: el}));

		$(id).magnificPopup({
			type: 'image',
			fixedContentPos: true,
			fixedBgPos: true,
			gallery: { enabled: true, tCounter: "" },
			items: items,
		});
	});

	$("[data-master]").magnificPopup({
		type: "image",
		fixedContentPos: true,
		fixedBgPos: true,
		gallery: { enabled: true, tCounter: "" },
		image: {
			tError: '<a href="%url%">Фото #%curr%</a> не загружено.',
			titleSrc: function (item) {
				return (
					item.el.data("name") + "<small>" + item.el.data("small") + "</small>"
				);
			},
		},
		zoom: { enabled: true },
		callbacks: {
			elementParse: function (item) {
				item.src = item.el.data("master");
			},
			open: function () {
				var mp = $.magnificPopup.instance,
						t = $(mp.currItem.el[0]);
				// console.log(t.data('id'));
				
				$.ajax({
					type: "GET",
					url: '/masters/' + t.data('id') + '/increment_view',
				})
			}
		},
	});

	$("#gallery").magnificPopup({
		delegate: "a.item",
		type: "image",
		fixedContentPos: true,
		fixedBgPos: true,
		gallery: { enabled: true, tCounter: "" },
		image: {
			tError: '<a href="%url%">Фото #%curr%</a> не загружено.',
			titleSrc: function (item) {
				return (
					item.el.data("name") + "<small>" + item.el.data("small") + "</small>"
				);
			},
		},
		zoom: { enabled: true },
	});

	$(".master__gallery").magnificPopup({
		delegate: "a.item",
		type: "image",
		fixedContentPos: true,
		fixedBgPos: true,
		gallery: { enabled: true, tCounter: "" },
		image: {
			tError: '<a href="%url%">Фото #%curr%</a> не загружено.',
			titleSrc: function (item) {
				return (
					item.el.data("name") + "<small>" + item.el.data("small") + "</small>"
				);
			},
		},
		zoom: { enabled: true },
	});
	
	$(".welcome__carousel")
		.on("initialized.owl.carousel changed.owl.carousel", function(e) {
			if (!e.namespace) {
				return;
			}
			$("#counter").text(
				e.relatedTarget.relative(e.item.index) + 1 + "/" + e.item.count
			);
		})
		.owlCarousel({
			items: 1,
			margin: 50,
			smartSpeed: 700,
			loop: true,
			autoplay: true,
			autoplayTimeout: 10000,
			autoplayHoverPause: true,
			nav: true,
			dots: true,
			navText,
		});
	$(".welcome__carousel2").owlCarousel({
		items: 1,
		margin: 50,
		smartSpeed: 700,
		loop: true,
		autoplay: true,
		autoplayTimeout: 10000,
		autoplayHoverPause: true,
		nav: true,
		dots: true,
		navText,
	});

	$(".master__gallery2 .owl-carousel").owlCarousel({
		items: 1,
		margin: 20,
		loop: true,
		nav: true,
		dots: false,
		navText,
	});
	
	// master gallery
	const bigimage = $(".master__gallery #preview");
	const thumbs = $(".master__gallery #thumbs");
	const syncedSecondary = true;
	
	bigimage
		.owlCarousel({
			items: 1,
			slideSpeed: 2000,
			autoplay: true,
			dots: false,
			nav: true,
			navText,
			loop: true,
		})
		.on("changed.owl.carousel", syncPosition);
	
	thumbs
		.on("initialized.owl.carousel", function () {
			thumbs.find(".owl-item").eq(0).addClass("current");
		})
		.owlCarousel({
			items: 4,
			dots: false,
			nav: false,
			margin: 10,
		})
		.on("changed.owl.carousel", syncPosition2);
	
	function syncPosition(el) {
		var count = el.item.count - 1;
		var current = Math.round(el.item.index - el.item.count / 2 - 0.5);
		
		if (current < 0) {
			current = count;
		}
		if (current > count) {
			current = 0;
		}
		
		//end block
		
		thumbs.find(".owl-item").removeClass("current").eq(current).addClass("current");
		var onscreen = thumbs.find(".owl-item.active").length - 1;
		var start = thumbs.find(".owl-item.active").first().index();
		var end = thumbs.find(".owl-item.active").last().index();
		
		if (current > end) {
			thumbs.data("owl.carousel").to(current, 100, true);
		}
		if (current < start) {
			thumbs.data("owl.carousel").to(current - onscreen, 100, true);
		}
	}
	
	function syncPosition2(el) {
		if (syncedSecondary) {
			var number = el.item.index;
			bigimage.data("owl.carousel").to(number, 100, true);
		}
	}
	
	thumbs.on("click", ".owl-item", function (e) {
		e.preventDefault();
		var number = $(this).index();
		bigimage.data("owl.carousel").to(number, 300, true);
	});
	
	var mapStyle = [
		{
			featureType: "all",
			elementType: "all",
			stylers: [{ saturation: "-70" }],
		},
	];
	var mapContainer = document.querySelector("#map");
	var map_location = [55.821588, 37.494875];
	const logoImageSrc = $('.logo_image').attr('src');
	var contentString = `
              <div id="content">
                <div id="bodyContent">
                  <img src="${logoImageSrc}"/>
                  <p>Москва, Ленинградское шоссе, д.17/1<br><b>Тел.:</b> +7 (985) 068 11 39<br><b>e-Mail:</b> info@tantrium.ru</p>
                </div>
              </div>
            `;

	if (mapContainer) {
		var map = new google.maps.Map(mapContainer, {
			zoom: 19,
			center: new google.maps.LatLng(map_location[0], map_location[1]),
			disableDefaultUI: true,
			styles: mapStyle,
		});

		var map_marker = new google.maps.Marker({
			position: new google.maps.LatLng(map_location[0], map_location[1]),
			map: map,
			icon: {
				url: $(mapContainer).data('marker'),
				scaledSize: new google.maps.Size(41, 48),
			},
		});

		var infowindow = new google.maps.InfoWindow({
			content: contentString,
		});

		map_marker.addListener("click", function () {
			infowindow.open(map, map_marker);
		});

		window.addEventListener("resize", function () {
			window.setTimeout(function () {
				map.panTo(map_marker.getPosition());
			}, 250);
		});
	}
});

$(function() {
	$('[data-toggle="tooltip"]').tooltip({
		html: true
	});
});

import "controllers"
