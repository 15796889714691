<template>
  <div v-if="status === 'free'">
    <a class="book" href="" v-on:click.prevent="click">
      <svg-icon icon="ch"/>
      <span class="text">бронировать</span>
    </a>
  </div>
  <div v-else-if="status === 'busy'">
    <span class="busy">
      <svg-icon icon="ch"/>
      <span class="text">занято</span>
    </span>
  </div>
  <div v-else-if="status === 'half-busy'">
    <span class="busy half-start">
      <svg-icon icon="ch"/>
      <span class="text">занято</span>
    </span>
    <a class="book half-end" href="" v-on:click.prevent="click">
      <svg-icon icon="ch"/>
      <span class="text">бронировать</span>
    </a>
  </div>
  <div v-else-if="status === 'half-free'">
    <a class="book half-start" href="" v-on:click.prevent="click">
      <svg-icon icon="ch"/><span class="text">бронировать</span></a>
    <span class="busy half-end">
      <svg-icon icon="ch"/>
      <span class="text">занято</span>
    </span>
  </div>
  <div v-else-if="status === 'past'" class="past">
    <div class="icon">&nbsp;</div>
  </div>
</template>
<script>
import SvgIcon from './svg_icon.vue';

export default {
  name: 'TimeSlot',
  components: {SvgIcon},
  props: {
    status: {
      type: String,
      required: true,
    },
    click: {type: Function},
  },
}
</script>
