import { Controller } from "stimulus"
import $ from "jquery";
import Dropzone from "dropzone";

// import consumer from "channels/consumer"
// import CableReady from 'cable_ready'

export default class extends Controller {
  
  static targets = [ ]
  
  initialize() {
    Dropzone.autoDiscover = false;

    (function (params) {
      if ($("#upload__image").length) {
        initDropzone("#upload__image", '/announcements/upload', false)
      };
    
      if ($("#master_upload__image").length) {
        initDropzone("#master_upload__image", '/masters/upload', false)
      };
    
      if ($("#multi_upload").length) {
        initDropzone("#multi_upload", '/masters/multiple_upload', true)
      };
    
      function initDropzone(id, url, uploadMultiple) {

        var myDropzone = new Dropzone(id, {
          acceptedFiles: "image/jpeg,image/jpg,image/png",
          url,
          uploadMultiple,
          maxFilesize: 800,
          parallelUploads: 800,
          dictRemoveFile: "",
          dictResponseError: "Refresh the page. Internet ERROR",
          dictFileTooBig: "File is too big ({{filesize}}MB). Max filesize: {{maxFilesize}}MB.",
          headers: {
            'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
          },
          sending: function (file, xhr, formData) {
            formData.append("resource_id", this.element.getAttribute("data-resource"))
          },
          success: function (file, response) {
            file.previewElement.innerHTML = "";
            this.removeFile(file);
          
            if (response.status === 'ok') {
              
              if (response.resource === 'announcement'){
                $('#announcement_picture_id').val(response.picture_id);
              } else if (response.resource === 'master') {
                $('#user_profile_picture_id').val(response.picture_id);
              }
              
              if (response.multiple === true) {
                $('#formGallery').html(response.formPhoto);
              } else {
                $('#formPhoto').html(response.formPhoto);
              }
            }
          }
        });
      }
    })();
  }
}
