$(function() {
    var check_parent, touch_element, uncheck_children;
    $('input.category_user_profile').change(function() {
        return touch_element(this);
    });
    touch_element = function(element) {
        if ($(element).prop('checked')) {
            return check_parent(element);
        } else {
            return uncheck_children(element);
        }
    };
    check_parent = function(element) {
        var parent, parent_id;
        parent_id = $(element).data('parent-id');
        if (!parent_id) {
            return;
        }
        parent = $(".category_user_profile[data-id='" + parent_id + "']");
        if ($(parent).prop('checked')) {
            return touch_element(parent);
        } else {
            $(parent).prop('checked', true);
            return touch_element(parent);
        }
    };
    return uncheck_children = function(element) {
        var child, children, i, id, len, results;
        id = $(element).data('id');
        children = $(".category_user_profile[data-parent-id='" + id + "'");
        results = [];
        for (i = 0, len = children.length; i < len; i++) {
            child = children[i];
            if (!$(child).prop('checked')) {
                results.push(touch_element(child));
            } else {
                $(child).prop('checked', false);
                results.push(touch_element(child));
            }
        }
        return results;
    };
});
