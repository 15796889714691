import { Controller } from "stimulus"
import $ from "jquery";

// import consumer from "channels/consumer"
// import CableReady from 'cable_ready'

export default class extends Controller {
  
  static targets = [ ]
  
  initialize() {
    $("[data-plugin='select2']").each(function () {
      const $eventSelect = $(this);
      const $dropdownParent = $eventSelect.parents(".form-select:first");
    
      $eventSelect.select2({
        placeholder: "Select an option",
        width: "100%",
        dropdownParent: $dropdownParent,
      });
    
      $eventSelect.on("select2:open", function (e) {
        setTimeout(() => {
          /*createScrollbar($dropdownParent.find(".select2-results__options")[0]);*/
        }, 20);
      });
    });
  
    $(".form-datepicker").each(function () {
      const $input = $(this).find(".form-control");
      $input.datepicker({
        language: "en",
        dateFormat: "dd/mm/yyyy",
        autoClose: true,
      });
      //.data("datepicker")
      //.selectDate(new Date());
    });
  }
}
