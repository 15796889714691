<template>
  <svg class="icon">
    <use :xlink:href="href"></use>
  </svg>
</template>
<script>
export default {
  name: 'SvgIcon',
  props: {
    icon: {
      type: String,
      required: true,
    }
  },
  computed: {
    href() {
      const spriteLink = document.head.querySelector('link[rel="sprite"]');
      return `${spriteLink.href}?#${this.icon}`;
    }
  }
}
</script>
